<template>
    <div class="newRapporto" style="height:100%;">
        <v-toolbar color="secondary" dense>
            <v-toolbar-title class="white--text">
                Rapporto
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!--v-btn small icon @click="remove_ddt()" color="white">
              <v-icon small>fas fa-trash</v-icon>
            </v-btn-->
            <v-btn small @click="setDataAutoLoad()" color="white" class="ml-3 mr-1" v-if="false">
                METTI
            </v-btn>
            <v-btn small icon @click="isEditing = !isEditing" color="white" class="ml-3">
                <v-icon small>fas fa-edit</v-icon>
            </v-btn>
            <v-btn small icon @click="$emit('rapportinoDone')" color="white" class="ml-3 mr-1">
                <v-icon small>fas fa-times</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card tile class="px-3">
            <b class="pl-1">Tipo</b>
            <v-radio-group v-model="tipo" :readonly="!isEditing" row class="flexChildren mt-1 pa-1 mb-2" hide-details="">
                <v-radio
                    label="Semestrale"
                    value="Semestrale"
                ></v-radio>
                <v-radio
                    label="Annuale"
                    value="Annuale"
                ></v-radio>
                <v-radio
                    label="Straordinaria"
                    value="Straordinaria"
                ></v-radio>
            </v-radio-group>
            <ListaEvacuatori :lista_evacuatori="array_evacuatori_selected" :id_stabilimento="id_stabilimento" v-if="array_evacuatori_selected && array_evacuatori_selected.length" :actionScheda="false" :hideFooter="true" id="lista_evacuatori_rapporto"/>
            <div id="reader"></div>
            <v-container grid-list-sm fluid v-if="isEditing" class="mt-2">
                <v-row>
                    <v-col cols="12" md="4" class="pt-0">
                        <v-btn :disabled="!stabilimento" color="primary" width="100%" @click="scanQRcode">Scan QR</v-btn>
                    </v-col>
                    <v-col cols="12" md="4" class="pt-0">
                        <v-btn :disabled="!stabilimento" color="primary" width="100%" @click="isChosingEvacuatore = !isChosingEvacuatore">Scegli dalla lista</v-btn>
                    </v-col>
                    <v-col cols="12" md="4" class="pt-0">
                        <v-btn :disabled="!stabilimento || !stabilimento.file" color="primary" width="100%" @click="isChosingEvacuatoreMappa = !isChosingEvacuatoreMappa">Scegli dalla mappa</v-btn>
                    </v-col>
                </v-row>
            </v-container>
            <v-expansion-panels v-show="activatePanels" :multiple="multiplePanels" v-model="modelPanels">
                <v-expansion-panel v-if="!libero">
                    <v-expansion-panel-header class="display-block-children">
                        <div style="width:95%">
                            <b>Aperture</b>
                            <v-icon color="#1a73e8" dense class="mr-2" style="float:right;" v-if="formAperture">
                                far fa-check-circle
                            </v-icon>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form v-model="formAperture" ref="form_aperture" lazy-formValidation class="pt-2">
                            <v-row>
                                <v-col cols="6" class="mt-3">
                                    Automatica
                                </v-col>
                                <v-col cols="6">
                                    <v-checkbox
                                        value
                                        :readonly="!isEditing"
                                        outlined
                                        dense
                                        class="mt-2"
                                        hide-details="auto"
                                        v-model="apertura_automatica"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="6" class="mt-3">
                                    Manuale
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        :readonly="!isEditing"
                                        :items="['Esito Positivo', 'Esito Negativo']"
                                        v-model="apertura_manuale"
                                        required
                                        outlined
                                        dense
                                        :rules="requiredRules"
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="!libero">
                    <v-expansion-panel-header class="display-block-children">
                        <div style="width:95%">
                            <b>Bombola</b>
                            <v-icon color="#1a73e8" dense class="mr-2" style="float:right;" v-if="formBombola">
                                far fa-check-circle
                            </v-icon>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form v-model="formBombola" ref="form_bombola" lazy-formValidation class="pt-2">
                            <v-row>
                                <v-col cols="6" class="mt-3">
                                    Sostituzione
                                </v-col>
                                <v-col cols="6">
                                    <v-checkbox
                                        value
                                        :readonly="!isEditing"
                                        outlined
                                        dense
                                        class="mt-2"
                                        hide-details="auto"
                                        v-model="bombola['sostituzione']"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" class="mt-3">
                                    Tipo
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        :readonly="!isEditing"
                                        :items="['J', 'M', 'Ottone']"
                                        v-model="bombola['tipo']"
                                        outlined
                                        dense
                                        required
                                        :rules="requiredRules"
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" class="mt-3">
                                    Grammi
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        :readonly="!isEditing"
                                        :items="lista_grammi_bombola"
                                        v-model="bombola['grammi']"
                                        outlined
                                        dense
                                        required
                                        :rules="requiredRules"
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row v-show="!bombola['sostituzione']">
                                <v-col cols="6" class="mt-3">
                                    Peso Teorico
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="bombola['peso_teorico']"
                                        :readonly="!isEditing"
                                        class="pt-0 mt-0"
                                        type="number"
                                        outlined
                                        dense
                                        label="GR"
                                        :required="!bombola['sostituzione']"
                                        :rules="!bombola['sostituzione'] ? requiredRules : []"
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row v-show="!bombola['sostituzione']">
                                <v-col cols="6" class="mt-3">
                                    Peso Attuale
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="bombola['peso_attuale']"
                                        :readonly="!isEditing"
                                        class="pt-0 mt-0"
                                        type="number"
                                        outlined
                                        dense
                                        label="GR"
                                        :required="!bombola['sostituzione']"
                                        :rules="!bombola['sostituzione'] ? pesoAttualeRules : []"
                                        hide-details="auto"
                                    ></v-text-field>
                                    <b><small v-if="bombola['peso_attuale'] && bombola['peso_teorico'] &&
                                (parseInt(bombola['peso_attuale']) - parseInt(bombola['peso_teorico'])) >= (parseInt(bombola['peso_teorico']) * 0.1)"
                                              style="color:red;">Superiore al 10%!</small></b>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="!libero">
                    <v-expansion-panel-header class="display-block-children">
                        <div style="width:95%">
                            <b>Valvola</b>
                            <v-icon color="#1a73e8" dense class="mr-2" style="float:right;" v-if="formValvola">
                                far fa-check-circle
                            </v-icon>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form v-model="formValvola" ref="form_valvola" lazy-formValidation class="pt-2">
                            <v-row>
                                <v-col cols="6" class="mt-3">
                                    Sostituzione
                                </v-col>
                                <v-col cols="6">
                                    <v-checkbox
                                        value
                                        :readonly="!isEditing"
                                        outlined
                                        dense
                                        class="mt-2"
                                        hide-details="auto"
                                        v-model="valvola['sostituzione']"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row v-show="!valvola['sostituzione']">
                                <v-col cols="6" class="mt-3">
                                    Verifica
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        :readonly="!isEditing"
                                        :items="['Esito Positivo', 'Esito Negativo']"
                                        v-model="valvola['verifica']"
                                        outlined
                                        dense
                                        class="mt-0 pt-0"
                                        :required="!valvola['sostituzione']"
                                        :rules="!valvola['sostituzione'] ? requiredRules : []"
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row v-show="!valvola['sostituzione']">
                                <v-col cols="6" class="mt-3">
                                    Scatto
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        :readonly="!isEditing"
                                        :items="['Esito Positivo', 'Esito Negativo']"
                                        v-model="valvola['scatto']"
                                        outlined
                                        dense
                                        class="mt-0 pt-0"
                                        :required="!valvola['sostituzione']"
                                        :rules="!valvola['sostituzione'] ? requiredRules : []"
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row v-show="!valvola['sostituzione']">
                                <v-col cols="6" class="mt-3">
                                    Lubrificazione
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        :readonly="!isEditing"
                                        :items="['Eseguita', 'Non Eseguita']"
                                        v-model="valvola['lubrificazione']"
                                        outlined
                                        dense
                                        class="mt-0 pt-0"
                                        :required="!valvola['sostituzione']"
                                        :rules="!valvola['sostituzione'] ? requiredRules : []"
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="!libero">
                    <v-expansion-panel-header class="display-block-children">
                        <div style="width:95%">
                            <b>Ampolla</b>
                            <v-icon color="#1a73e8" dense class="mr-2" style="float:right;" v-if="formAmpolla">
                                far fa-check-circle
                            </v-icon>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form v-model="formAmpolla" ref="form_ampolla" lazy-formValidation class="pt-2">
                            <v-row>
                                <v-col cols="6" class="mt-3">
                                    Sostituzione
                                </v-col>
                                <v-col cols="6">
                                    <v-checkbox
                                        value
                                        :readonly="!isEditing"
                                        outlined
                                        dense
                                        class="mt-2"
                                        hide-details="auto"
                                        v-model="ampolla['sostituzione']"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" class="mt-3">
                                    Tipo
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        :readonly="!isEditing"
                                        :items="['J', 'M']"
                                        v-model="ampolla['tipo']"
                                        required
                                        outlined
                                        dense
                                        class="mt-0 pt-0"
                                        :rules="requiredRules"
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" class="mt-3">
                                    Gradi
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        :readonly="!isEditing"
                                        :items="lista_gradi_ampolla"
                                        v-model="ampolla['gradi']"
                                        required
                                        outlined
                                        dense
                                        class="mt-0 pt-0"
                                        :rules="requiredRules"
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-show="!libero && !nuovo_evaquatore">
                    <v-expansion-panel-header class="display-block-children">
                        <div style="width:95%">
                            <b>Pirotecnico</b>
                            <v-icon color="#1a73e8" dense class="mr-2" style="float:right;" v-if="formPirotecnico">
                                far fa-check-circle
                            </v-icon>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form v-model="formPirotecnico" ref="form_pirotecnico" lazy-formValidation class="pt-2">
                            <v-row>
                                <v-col cols="6" class="mt-3">
                                    Presente
                                </v-col>
                                <v-col cols="6">
                                    <v-checkbox
                                        value
                                        :readonly="!isEditing"
                                        outlined
                                        dense
                                        class="mt-2"
                                        hide-details="auto"
                                        v-model="pirotecnico['presente']"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row v-if="pirotecnico['presente']">
                                <v-col cols="6" class="mt-3">
                                    Collegato
                                </v-col>
                                <v-col cols="6">
                                    <v-checkbox
                                        value
                                        :readonly="!isEditing"
                                        outlined
                                        dense
                                        class="mt-2"
                                        hide-details="auto"
                                        v-model="pirotecnico['collegato']"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row v-if="pirotecnico['collegato'] && pirotecnico['presente']">
                                <v-col cols="6" class="mt-3">
                                    Verifica 24V
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        :readonly="!isEditing"
                                        :items="['Collegato', 'Non Collegato']"
                                        v-model="pirotecnico['verifica']"
                                        :required="pirotecnico['collegato'] && pirotecnico['presente']"
                                        :rules="pirotecnico['collegato'] && pirotecnico['presente'] ? requiredRules : []"
                                        outlined
                                        dense
                                        class="mt-0 pt-0"
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row v-if="pirotecnico['collegato'] && pirotecnico['presente']">
                                <v-col cols="6" class="mt-3">
                                    Tipo
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        :readonly="!isEditing"
                                        :items="['J', 'M']"
                                        v-model="pirotecnico['tipo']"
                                        :required="pirotecnico['collegato'] && pirotecnico['presente']"
                                        outlined
                                        dense
                                        class="mt-0 pt-0"
                                        :rules="pirotecnico['collegato'] && pirotecnico['presente'] ? requiredRules : []"
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row v-if="pirotecnico['collegato'] && pirotecnico['presente']">
                                <v-col cols="6" class="mt-3">
                                    Dettagli
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        :readonly="!isEditing"
                                        autocomplete="off"
                                        v-model="pirotecnico['dettagli']"
                                        hide-details="auto"
                                        label="Dettagli Pirotecnico"
                                        class="mt-0 pt-1"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="!libero && !nuovo_evaquatore">
                    <v-expansion-panel-header class="display-block-children">
                        <div style="width:95%">
                            <b>Elettromagnete</b>
                            <v-icon color="#1a73e8" dense class="mr-2" style="float:right;" v-if="formElettromagnete">
                                far fa-check-circle
                            </v-icon>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form v-model="formElettromagnete" ref="form_elettromagnete" lazy-formValidation class="pt-2">
                            <v-row>
                                <v-col cols="6" class="mt-3">
                                    Presente
                                </v-col>
                                <v-col cols="6">
                                    <v-checkbox
                                        value
                                        :readonly="!isEditing"
                                        outlined
                                        dense
                                        class="mt-2"
                                        hide-details="auto"
                                        v-model="elettromagnete['presente']"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row v-if="elettromagnete['presente']">
                                <v-col cols="6" class="mt-3">
                                    Collegato
                                </v-col>
                                <v-col cols="6">
                                    <v-checkbox
                                        value
                                        :readonly="!isEditing"
                                        outlined
                                        dense
                                        class="mt-2"
                                        hide-details="auto"
                                        v-model="elettromagnete['collegato']"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="!libero && nuovo_evaquatore">
                    <v-expansion-panel-header class="display-block-children">
                        <div style="width:95%">
                            <b>Attuoatore</b>
                            <v-icon color="#1a73e8" dense class="mr-2" style="float:right;" v-if="formAttuatore">
                                far fa-check-circle
                            </v-icon>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form v-model="formAttuatore" ref="form_attuatore" lazy-formValidation class="pt-2">
                            <v-row v-if="attuatore['collegato']">
                                <v-col cols="6" class="mt-3">
                                    Tipo
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        :readonly="!isEditing"
                                        :items="['pirotecnico', 'elettromagnete', 'assente']"
                                        v-model="attuatore['tipo']"
                                        :required="attuatore['collegato']"
                                        outlined
                                        dense
                                        class="mt-0 pt-0"
                                        :rules="attuatore['collegato'] ? requiredRules : []"
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" class="mt-3">Collegato</v-col>
                                <v-col cols="6">
                                    <v-checkbox
                                        value
                                        :readonly="!isEditing"
                                        outlined
                                        dense
                                        class="mt-2"
                                        hide-details="auto"
                                        v-model="attuatore['collegato']"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="!libero">
                    <v-expansion-panel-header class="display-block-children">
                        <div style="width:95%">
                            <b>Motore</b>
                            <v-icon color="#1a73e8" dense class="mr-2" style="float:right;" v-if="formMotore">
                                far fa-check-circle
                            </v-icon>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form v-model="formMotore" ref="form_motore" lazy-formValidation class="pt-2">
                            <v-row>
                                <v-col cols="6" class="mt-3">
                                    Presente
                                </v-col>
                                <v-col cols="6">
                                    <v-checkbox
                                        value
                                        :readonly="!isEditing"
                                        outlined
                                        dense
                                        class="mt-2"
                                        hide-details="auto"
                                        v-model="motore['presente']"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row v-if="motore['presente']">
                                <v-col cols="6" class="mt-3">
                                    Collegato
                                </v-col>
                                <v-col cols="6">
                                    <v-checkbox
                                        value
                                        :readonly="!isEditing"
                                        outlined
                                        dense
                                        class="mt-2"
                                        hide-details="auto"
                                        v-model="motore['collegato']"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <b>Altro</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form class="pt-2">
                            <v-row>
                                <v-col cols="6" class="mt-3">
                                    Note
                                </v-col>
                                <v-col cols="6">
                                    <v-textarea
                                        :readonly="!isEditing"
                                        autocomplete="off"
                                        label="Note"
                                        v-model="note"
                                        hide-details
                                        outlined
                                        dense
                                        auto-grow
                                        rows="1"
                                        class="mb-3"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" class="mt-1">
                                    Video
                                </v-col>
                                <v-col cols="6">
                                    <v-file-input
                                        :disabled="!isEditing"
                                        accept="video/mp4,video/x-m4v,video/*"
                                        @change="onFileChange"
                                        label="File"
                                        dense
                                        v-model="fileVideo"
                                        truncate-length="25"
                                    ></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row v-if="(fileVideo && fileVideo.src) || srcFileVideoUpload">
                                <v-col cols="12" class="mt-1">
                                    <video width="100%" height="auto" controls ref="videoTag">
                                        <source v-if="fileVideo && fileVideo.src" :src="fileVideo.src">
                                        <source v-if="srcFileVideoUpload" :src="srcFileVideoUpload">
                                        Impossibile aprire il video
                                    </video>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" class="mt-1">
                                    Immagini
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <VueUploadMultipleImage
                                        :showEdit="false"
                                        :showDelete="isEditing"
                                        :showAdd="isEditing"
                                        :disabled="!isEditing"
                                        dragText="Carica Immagini"
                                        browseText="Drag & Drop"
                                        dropText="Rilascia Qui"
                                        accept="image/gif,image/jpeg,image/png,image/bmp,image/jpg,image/*"
                                        class="uploaderFix"
                                        @upload-success="imageListChanged"
                                        @before-remove="removeImage"
                                        @edit-image="imageListChanged"
                                        :data-images="immagini"
                                    />
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-card-actions>
                <v-col cols="12" md="4" offset-md="4" sm="12" class="pt-0">
                    <v-btn color="primary" width="100%" @click="submit" :disabled="isEditing && loading" v-text="isEditing ? 'Salva' : 'Indietro'"></v-btn>
                </v-col>
            </v-card-actions>
        </v-card>
        <v-dialog
            v-model="isChosingEvacuatore"
            width="auto"
            max-width="600"
        >
            <ListaEvacuatori :id_stabilimento="id_stabilimento ? id_stabilimento : (stabilimento ? stabilimento['_id'] : '')" :list_selected="array_evacuatori_selected" :isChosing="true" :multipleChosing="false" @evacuatoreClick="evacuatoreClick" v-if="stabilimento && isChosingEvacuatore" :actionScheda="false"/>
        </v-dialog>
        <v-dialog
            v-model="isChosingEvacuatoreMappa"
            max-width="90vw"
        >
            <MappaEvacuatori :id_stabilimento="id_stabilimento ? id_stabilimento : (stabilimento ? stabilimento['_id'] : '')" :list_selected="array_evacuatori_selected" :isChosing="true" :multipleChosing="false" @evacuatoreClick="evacuatoreClick" v-if="stabilimento && isChosingEvacuatoreMappa"/>
        </v-dialog>
    </div>
</template>

<script>
import apiEvacuatori from '@/js/pages/evacuatori';
import apiStabilimenti from '@/js/pages/stabilimenti';
import apiRapporti from '@/js/pages/rapporti';
import { requiredRules, numberRequiredRules } from '@/js/validationRules';
import VueUploadMultipleImage from 'vue-upload-multiple-image';
const ListaEvacuatori = () => import('@/components/Lista_evacuatori');
const MappaEvacuatori = () => import('@/components/Mappa_evacuatori');

export default {
    name: 'newRapporto',
    props: ['id_attivita', 'id', 'id_cliente', 'id_stabilimento', 'libero'],
    data() {
        return {
            token: this.$cookies.get('token'),

            formAltro: false,
            formMotore: true,
            formElettromagnete: true,
            formPirotecnico: false,
            formAttuatore: false,
            formAmpolla: false,
            formAperture: false,
            formBombola: false,
            formValvola: false,
            loading: false,
            isEditing: false,
            lista_grammi_bombola: ['20g', '30g', '40g', '50g', '80g', '100g', '150g'],
            lista_gradi_ampolla: ['68°', '93°', '141°', '182°'],

            isChosingEvacuatore: false,
            isChosingEvacuatoreMappa: false,
            array_evacuatori_selected: [],
            stabilimento: undefined,

            id_evacuatore: undefined,
            numero: 0,
            tipo: '',
            apertura_manuale: '',
            apertura_automatica: '',
            bombola: {
                sostituzione: false,
                tipo: '',
                grammi: '',
                peso_teorico: '',
                peso_attuale: '',
            },
            valvola: {
                sostituzione: false,
                verifica: '',
                scatto: '',
                lubrificazione: '',
            },
            ampolla: {
                sostituzione: false,
                tipo: '',
                gradi: '',
            },
            pirotecnico: {
                presente: false,
                collegato: false,
                tipo: '',
                dettagli: '',
                verifica: '',
            },
            elettromagnete: {
                presente: false,
                collegato: false,
            },
            attuatore: {
                tipo: '',
                collegato: false,
            },
            motore: {
                presente: false,
                collegato: false,
            },
            note: '',

            requiredRules: requiredRules,
            pesoAttualeRules: requiredRules,
            numberRequiredRules: numberRequiredRules,
            modelPanels: [0,1,2,3,4,5],
            multiplePanels: true,
            activatePanels: false,
            isLoadingQR: false,
            fileVideo: undefined,
            immagini: [],
            srcFileVideoUpload: '',
            nuovo_evaquatore: true
        }
    },
    components: {
        VueUploadMultipleImage,
        ListaEvacuatori,
        MappaEvacuatori
    },
    watch: {
        async array_evacuatori_selected() {
            if(
                this.array_evacuatori_selected &&
                this.array_evacuatori_selected.length
            ) {
                let evacuatore = await apiEvacuatori.getOne(this.token, this.array_evacuatori_selected[0]);
                if(evacuatore) {
                    if(evacuatore['scheda']) {
                        if (evacuatore.scheda.nuovo) this.nuovo_evaquatore = true;
                        else this.nuovo_evaquatore = false;

                        if(this.isEditing) {
                            if (
                                !this.nuovo_evaquatore &&
                                evacuatore['scheda']['pirotecnico'] &&
                                evacuatore['scheda']['marca_pirotecnico']
                            ) {
                                this.pirotecnico['presente'] = true;
                                this.pirotecnico['collegato'] = true;
                                this.pirotecnico['dettagli'] = evacuatore['scheda']['pirotecnico'];
                                this.pirotecnico['tipo'] = evacuatore['scheda']['marca_pirotecnico'];
                            }
                            if (this.nuovo_evaquatore) {
                                this.attuatore['tipo'] = evacuatore['scheda']['attuatore'];
                                this.attuatore['collegato'] = true;
                            }
                            this.bombola['tipo'] = evacuatore['scheda']['marca_bombola'];

                            let grammiBombola = evacuatore['scheda']['bombola'];
                            if (grammiBombola.indexOf('g') === -1) grammiBombola += 'g';
                            if (this.lista_grammi_bombola.indexOf(grammiBombola) === -1) this.lista_grammi_bombola.push(grammiBombola);
                            this.bombola['grammi'] = grammiBombola;
                            this.ampolla['tipo'] = evacuatore['scheda']['marca_ampolla'];
                            let gradiAmpolla = evacuatore['scheda']['ampolla'];
                            if (grammiBombola.indexOf('°') === -1) gradiAmpolla += '°';
                            if (this.lista_gradi_ampolla.indexOf(gradiAmpolla) === -1) this.lista_gradi_ampolla.push(gradiAmpolla);
                            this.ampolla['gradi'] = gradiAmpolla;
                        }
                    }
                }
            }
        }
    },
    async created() {
        let those = this;
        setTimeout(() => {
            those.modelPanels = [];
            those.multiplePanels = false;
            setTimeout(() => {
                those.activatePanels = true;
            }, 10);
        }, 10);
        if(!this.id) this.isEditing = true;
        if(this.id_stabilimento) this.stabilimento = await apiStabilimenti.getOne(this.id_stabilimento);
        if(this.id) {
            let rapporto = await apiRapporti.getOne(this.token, this.id);
            this.stabilimento = await apiStabilimenti.getOne(rapporto.attivita.id_stabilimento);
            this.id_stabilimento = this.stabilimento['_id'];
            this.cliente = rapporto.attivita.id_cliente;
            this.numero = rapporto.numero;

            this.array_evacuatori_selected = rapporto.id_evacuatore ? [rapporto.id_evacuatore] : [];

            this.id_evacuatore = rapporto.id_evacuatore || undefined;
            this.numero = rapporto.numero || 0;
            this.tipo = rapporto.tipo || '';
            this.apertura_manuale = rapporto.apertura_manuale || '';
            this.apertura_automatica = rapporto.apertura_automatica || false;
            this.bombola = rapporto.bombola || {};
            this.valvola = rapporto.valvola || {};
            this.ampolla = rapporto.ampolla || {};
            this.pirotecnico = rapporto.pirotecnico || {};
            this.attuatore = rapporto.attuatore || {};
            this.motore = rapporto.motore || {};
            this.elettromagnete = rapporto.elettromagnete || {};
            this.note = rapporto.note || '';

            if(rapporto.video){
                let nomeFile = '';
                nomeFile = rapporto.video.substring(rapporto.video.lastIndexOf("/")+1);
                nomeFile = nomeFile.substring(0, nomeFile.lastIndexOf("_")) + nomeFile.substring(nomeFile.lastIndexOf("."));
                this.fileVideo = {
                    name: nomeFile,
                    src: 'https://app.lacelle.it:5000/'+rapporto.video,
                    size: 10
                };
            } else this.fileVideo = undefined;
            if(
                rapporto.immagini &&
                rapporto.immagini.length
            ) {
                for (const immagine of rapporto.immagini) {
                    let nomeFile = '';
                    nomeFile = immagine.substring(immagine.lastIndexOf("/")+1);
                    nomeFile = nomeFile.substring(0, nomeFile.lastIndexOf("_")) + nomeFile.substring(nomeFile.lastIndexOf("."));
                    this.immagini.push({
                        default: 0,
                        highlight: 0,
                        path: 'https://app.lacelle.it:5000/'+immagine,
                        finalName: immagine,
                        name: nomeFile
                    });
                }
            }
        } else if(
            this.stabilimento &&
            this.stabilimento['frequenza_controlli']
        ) this.tipo = this.stabilimento['frequenza_controlli'];
    },
    methods: {
        async submit(){
            if(this.isEditing){
                this.loading = true;

                if(this.$refs.form_aperture) this.$refs.form_aperture.validate();
                if(this.$refs.form_ampolla) this.$refs.form_ampolla.validate();
                if(this.$refs.form_bombola) this.$refs.form_bombola.validate();
                if(this.$refs.form_valvola) this.$refs.form_valvola.validate();
                if (!this.nuovo_evaquatore) {
                    if (this.$refs.form_pirotecnico) this.$refs.form_pirotecnico.validate();
                    if (this.$refs.form_elettromagnete) this.$refs.form_elettromagnete.validate();
                } else {
                    if (this.$refs.form_attuatore) this.$refs.form_attuatore.validate();
                }
                if(this.$refs.form_motore) this.$refs.form_motore.validate();
                if(
                    this.id_evacuatore &&
                    (
                        this.libero
                    ) || (
                        this.formMotore &&
                        this.formAmpolla &&
                        this.formAperture &&
                        this.formBombola &&
                        this.formValvola &&
                        (
                            (
                                !this.nuovo_evaquatore &&
                                this.formElettromagnete &&
                                this.formPirotecnico
                            ) || (
                                this.nuovo_evaquatore &&
                                this.formAttuatore
                            )
                        )
                    )
                ){
                    let data = {
                        id_attivita: this.id_attivita,
                        id_evacuatore: this.id_evacuatore,
                        tipo: this.tipo,
                        apertura_manuale: this.apertura_manuale,
                        apertura_automatica: this.apertura_automatica,
                        bombola: this.bombola,
                        valvola: this.valvola,
                        ampolla: this.ampolla,
                        pirotecnico: this.pirotecnico,
                        elettromagnete: this.elettromagnete,
                        attuatore: this.attuatore,
                        motore: this.motore,
                        note: this.note,
                    };

                    if(this.id) data._id = this.id;

                    let myId = this.id;
                    if(this.id) await apiRapporti.updateOne(this.token, data);
                    else myId = await apiRapporti.insertNew(this.token, data);

                    if(this.fileVideo) {
                        let formData = new FormData();
                        formData.append("filevideo", this.fileVideo);
                        await apiRapporti.sendFileVideo(this.token, myId, formData);
                    } else await apiRapporti.removeFileVideo(this.token, { id_rapporto: myId });
                    let formData = new FormData();
                    for (const immagine of this.immagini) {
                        if(immagine.path.substring(0,25) == 'https://app.lacelle.it:5000') formData.append("immagini", new Blob(), immagine.finalName);
                        else {
                            let finalBlob = await fetch(immagine.path).then(res => res.blob());
                            formData.append("immagini", finalBlob, immagine.name);
                        }
                    }
                    await apiRapporti.sendFileImmagini(this.token, myId, formData);
                    this.$emit('rapportinoDone','reloadRapporti');
                }
                this.loading = false;
            } else this.$emit('rapportinoDone');
        },
        async remove_rapporto(){
            if(confirm("Eliminare il Rapporto?")){
                let data = { _id: this.id };
                await apiRapporti.deleteOne(this.token, data);
                this.$emit('rapportinoDone');
            }
        },
        addNewElement() {
            this.elementi.push({
                fake_num: 0,
                quantita: 1,
                unita_misura: '',
                descrizione: '',
            });
        },
        async evacuatoreClick(evacuatore) {
            this.array_evacuatori_selected = [];
            if(evacuatore){
                let those = this;
                setTimeout(() => {
                    those.array_evacuatori_selected = [evacuatore._id];
                    those.id_evacuatore = evacuatore._id;
                }, 10);
            }
            this.isChosingEvacuatore = false;
            this.isChosingEvacuatoreMappa = false;
        },
        async scanQRcode() {
            //https://github.com/mebjas/html5-qrcode
            if(!this.isLoadingQR) {
                this.isLoadingQR = true;
                Html5Qrcode.getCameras().then(devices => {
                    if (devices && devices.length) {
                        const html5QrCode = new Html5Qrcode("reader");
                        html5QrCode.start(
                            { facingMode: 'environment' },
                            {
                                fps: 10,
                                qrbox: 250
                            },
                            async (qrCodeMessage) => {
                                if(qrCodeMessage) {
                                    const urlParams = new URLSearchParams(new URL(qrCodeMessage).searchParams);
                                    const qrFound = urlParams.get('qr');
                                    let id_evacuatore = await apiEvacuatori.getOneFromMatricola(this.token, qrFound);
                                    if(id_evacuatore) this.array_evacuatori_selected = [id_evacuatore];
                                    setTimeout(() => {
                                        html5QrCode.stop();
                                        document.getElementById('reader').innerHTML = '';
                                        this.isLoadingQR = false;
                                    }, 100);
                                }
                            })
                            .catch(err => {
                                alert('big_errore -> ',err);
                            });
                    }
                }).catch(err => {
                    console.log('error cameras',err);
                });
            }
        },
        setDataAutoLoad() {
            this.tipo = 'Semestrale';
            this.apertura_manuale = 'Esito Positivo';
            this.bombola = {
                sostituzione: false,
                tipo: 'J',
                grammi: '30g',
                peso_teorico: '42',
                peso_attuale: '42',
            };
            this.valvola['sostituzione'] = true;
            this.ampolla = {
                sostituzione: false,
                tipo: 'M',
                gradi: '93°',
            };
            this.pirotecnico['elettromagnete'] = 'Non Collegato';
            this.pirotecnico['motore'] = 'Non Collegato';
        },
        imageListChanged(formData, index, fileList) {
            this.immagini = fileList;
        },
        async removeImage(index, done, fileList) {
            let r = confirm("Rimuovere l'immagine?");
            if (r) {
                await done();
                this.immagini = fileList;
            }
        },
        onFileChange(input) {

            if (input.files && input.files[0]) {
                var reader = new FileReader();

                reader.onload = function(e) {
                    this.srcFileVideoUpload = e.target.result;
                };

                reader.readAsDataURL(input.files[0]);
            }

            /*this.srcFileVideoUpload = URL.createObjectURL(this.fileVideo);
            if(this.$refs) this.$refs.videoTag.load();*/

            /*var reader = new FileReader();

            reader.onload = function(e) {
                this.srcFileVideoUpload = e.target.result;
            };

            reader.readAsDataURL(this.fileVideo);*/

        }
    },
}
</script>

<style>
.flexChildren {
    width: 100%;
}
.flexChildren .v-radio {
    flex: 1 1 0px;
}
.display-block-children > *:not(.v-expansion-panel-header__icon) {
    flex: none !important;
}
.image-bottom-left{
    opacity: 0 !important;
}
</style>